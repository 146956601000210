import React from 'react'
import AddItemForm from '../components/AddItemForm'

const AddItem = () => {
    return (
        <div className='container box'>
            <AddItemForm />
        </div>
    )
}

export default AddItem