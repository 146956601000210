import React from 'react'

const Contact = () => {
  return (
    <div>Contact</div>

    /*
  ---  DONOR  ---

  PAGES
  leaderboard
  all listed items
  view item page (donor)
  edit item page
  add item page
  
  COMPONENTS
  view needy modal
  
  
  
  ---  NEEDY  ---
  PAGES
  all items
  view item page (needy)
  list of items applied for

  */
  )

}
export default Contact